import { useState } from 'react';
import './About.css';

const timeSinceStart = (exact?: boolean) => {
    const currentDate = new Date();
    const pastDate = new Date('2019-02-01');

    let years = currentDate.getFullYear() - pastDate.getFullYear();
    let months = currentDate.getMonth() - pastDate.getMonth();
    let days = currentDate.getDate() - pastDate.getDate();

    // Adjust for month and day overflow
    if (days < 0) {
        months--;
        days += new Date(pastDate.getFullYear(), pastDate.getMonth() + 1, 0).getDate();
    }

    if (months < 0) {
        years--;
        months += 12;
    }

    let value = '';

    if (exact) {
        value = `${years} years, ${months} months and ${days} days`;
    } else {
        value = `over ${years}`;
        if (months >= 6) {
            value += ' and a half';
        }
        value += ' years';
    }

    return value;
};

type Props = React.HTMLProps<HTMLDivElement> & {};

function About({ ...rest }: Props) {
    const [timeCoding, setTimeCoding] = useState({ exact: false, string: timeSinceStart() });

    const toggleTimeCoding = () => {
        const string = timeSinceStart(!timeCoding.exact);
        setTimeCoding({ exact: !timeCoding.exact, string });
    };

    return (
        <section {...rest}>
            <h3 className='section-header'>About</h3>
            <p>
                Back in May 2018, I decided I wanted to work in tech. So I joined a{' '}
                <a
                    href='https://www.facebook.com/craftacademysweden/'
                    rel='noreferrer noopener'
                    target='_blank'
                >
                    coding bootcamp
                </a>{' '}
                which was a massive leap of faith. Fast forward to today and I have had the pleasure of
                building software for a handful of companies.
            </p>
            <p>
                I have{' '}
                <span className='dynamic-text' onClick={() => toggleTimeCoding()}>
                    {timeCoding.string}
                </span>{' '}
                of professional experience building frontend applications in{' '}
                <a href='https://react.dev/' rel='noreferrer noopener' target='_blank'>
                    React
                </a>
                ,{' '}
                <a href='https://angular.io/' rel='noreferrer noopener' target='_blank'>
                    Angular
                </a>
                ,{' '}
                <a href='https://vuejs.org/' rel='noreferrer noopener' target='_blank'>
                    Vue
                </a>{' '}
                and{' '}
                <a href='https://ionicframework.com/' rel='noreferrer noopener' target='_blank'>
                    Ionic
                </a>
                . I use{' '}
                <a href='https://www.typescriptlang.org/' rel='noreferrer noopener' target='_blank'>
                    TypeScript
                </a>{' '}
                whenever I have the chance.
            </p>
            <p>When I'm not working, I'm usually training Muay Thai, at the gym or playing EU4.</p>
        </section>
    );
}

export default About;
