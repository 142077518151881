import './ShinyBar.css';

type Position = 'top' | 'bottom';

type Props = {
    shade: 'lighter' | 'darker';
    position: Position;
};

function ShinyBar({ shade, position }: Props) {
    return <div className={`shiny-bar ${shade} ${position}`} />;
}

export { ShinyBar };
