import React, { useContext, useState } from 'react';
import { ModalContext } from '../Modal/modal-context';
import { FailedQuizAttemptModal } from '../../Components/FailedQuizAttemptModal/FailedQuizAttemptModal';
import { useNavigate } from 'react-router-dom';
import { useCurrentQuestionId } from '../../routes/Quiz/shared/useCurrentQuestionId';
import { questions } from '../..';
import { lsSetCurrentQuestion } from '../../routes/Quiz/shared/localStorage';

type ContextProps = {
    maxGuesses: number;
    guessesLeft: number;
    handleSetGuessesLeft: (increment: 1 | -1) => void;
    handleCorrectAnswer: () => void;
};

const GuessCountContext = React.createContext<ContextProps>({
    maxGuesses: 0,
    guessesLeft: 0,
    handleSetGuessesLeft: () => null,
    handleCorrectAnswer: () => null,
});

const GuessCountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [guessesLeft, setGuessesLeft] = useState<number>(4);
    const { setModal, unSetModal, setDisableModal } = useContext(ModalContext);
    const navigate = useNavigate();

    const currentQuestionPath = useCurrentQuestionId();

    const handleFailure = (tryAgain: boolean) => {
        const redirectPath = tryAgain ? '/quiz/questions/1' : '/';

        setGuessesLeft(4);
        lsSetCurrentQuestion(1);
        navigate(redirectPath);
        unSetModal(undefined);
    };

    const handleSetGuessesLeft = (increment: 1 | -1) => {
        const newAmount = guessesLeft + increment;
        setGuessesLeft(newAmount);

        if (newAmount <= 0) {
            setDisableModal(true);
            setModal(<FailedQuizAttemptModal callback={handleFailure} />);
        }
    };

    const handleCorrectAnswer = () => {
        const nextQuestionPath = `/quiz/questions/${currentQuestionPath + 1}`;
        const matchingPath = questions.find(({ path }) => path === nextQuestionPath);

        if (matchingPath) {
            lsSetCurrentQuestion(currentQuestionPath + 1);
            navigate(nextQuestionPath);
        } else {
            lsSetCurrentQuestion(1);
            navigate('/quiz/success');
        }
    };

    const value = { guessesLeft, handleSetGuessesLeft, maxGuesses: 4, handleCorrectAnswer };

    return <GuessCountContext.Provider value={value}>{children}</GuessCountContext.Provider>;
};

export { GuessCountProvider, GuessCountContext };
