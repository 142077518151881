import './Header.css';
import { ReactComponent as GithubSvg } from '../../media/svg/github.svg';
import { ReactComponent as LinkedinSvg } from '../../media/svg/linkedin.svg';
import { BaseSyntheticEvent } from 'react';
import { about, experience } from '../../routes/Home/Home';

type Props = {
    className?: string;
    sectionInView: string;
};

function Header({ className, sectionInView }: Props) {
    const handleClick = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        const id = e.target.hash.split('#')[1];

        const el = document.getElementById(id);

        el!.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    };

    return (
        <header className={className}>
            <div>
                <h1 className='mb-1'>Zakary Howard</h1>
                <h2 className='mb-2'>Frontend Developer</h2>
                <p className='mb-3 my-description'>
                    I bring designs to life, crafting interactive, user-friendly websites that look great and
                    function seamlessly.
                </p>

                <nav>
                    <ul>
                        <li className={`${sectionInView === about ? 'active' : ''} nav-link `}>
                            <span className='mr-1' />
                            <a href={`#${about}`} onClick={(e) => handleClick(e)}>
                                ABOUT
                            </a>
                        </li>
                        <li className={`${sectionInView === experience ? 'active' : ''} nav-link`}>
                            <span className='mr-1' />
                            <a href={`#${experience}`} onClick={(e) => handleClick(e)}>
                                EXPERIENCE
                            </a>
                        </li>
                        {/* <li className={`${sectionInView === blog ? 'active' : ''} nav-link `}>
                            <span className='mr-1' />
                            <a href={`#${blog}`} onClick={(e) => handleClick(e)}>
                                BLOG
                            </a>
                        </li> */}
                    </ul>
                </nav>
            </div>
            <div className='icon-links-group'>
                <a
                    href='https://github.com/Prozak8'
                    className='icon-link'
                    rel='noreferrer noopener'
                    target='_blank'
                >
                    <GithubSvg />
                </a>
                <a
                    href='https://www.linkedin.com/in/zdh/'
                    className='icon-link'
                    rel='noreferrer noopener'
                    target='_blank'
                >
                    <LinkedinSvg />
                </a>
            </div>
        </header>
    );
}

export default Header;
