import './fonts/Nougat-ExtraBlack.ttf';
import './styling/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import Home from './routes/Home/Home';
import Question1 from './routes/Quiz/Questions/Question1/Question1';
import Question2 from './routes/Quiz/Questions/Question2/Question2';
import PageNotFound from './Components/PageNotFound/PageNotFound';

// const Providers = () => (
//     <ModalProvider>
//         <GuessCountProvider>
//             <HomeButton />
//             <Outlet />
//         </GuessCountProvider>
//     </ModalProvider>
// );

export const questions = [
    {
        path: '/quiz/questions/1',
        element: <Question1 />,
    },
    {
        path: '/quiz/questions/2',
        element: <Question2 />,
    },
];

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
