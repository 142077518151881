import * as React from 'react';
import { Link } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import { ShinyBar } from '../ShinyBar/ShinyBar';
import './Button.css';

// Helpful guide on polymorphic button components
// https://dev.to/frehner/polymorphic-button-component-in-typescript-c28

type BaseProps = {
    styleType: 'primary' | 'success';
    text: string;
    Icon?: any;
    subText?: string;
};

type ButtonAsLink = BaseProps &
    Omit<LinkProps, keyof BaseProps> & {
        as: 'link';
    };

type ButtonAsIconOnly = Omit<BaseProps, 'text' | 'subText'> &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
        as: 'icon-only';
        Icon: () => JSX.Element;
        to?: string;
    };

type ButtonAsButton = BaseProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
        as?: 'button';
    };

type ButtonAsTextButton = BaseProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
        as: 'text-button';
    };

type ButtonProps = ButtonAsButton | ButtonAsLink | ButtonAsIconOnly | ButtonAsTextButton;

const DefaultInner = (props: ButtonAsButton | ButtonAsLink): JSX.Element => {
    const paddingLeft = () => {
        if (props.Icon) return { paddingLeft: 'calc(2rem + 50px)' };
    };

    const { Icon } = props;

    return (
        <>
            <ShinyBar position='top' shade='lighter' />
            {Icon && <div className='button__icon-wrapper'>{<Icon />}</div>}
            <div className='button__text-group' style={paddingLeft()}>
                <span className='button__text-group__title text-shadow'>{props.text.toUpperCase()}</span>
                {props.subText && (
                    <span className='button__text-group__sub-title'>{props.subText.toUpperCase()}</span>
                )}
            </div>
            <ShinyBar shade='darker' position='bottom' />
        </>
    );
};

function Button(props: ButtonProps): JSX.Element {
    const allClassNames = `button ${props.styleType ? props.styleType : ''} ${
        props.className ? props.className : ''
    }`;

    if (props.as === 'link') {
        // don't pass unnecessary props to component
        const { className, styleType, as, subText, Icon, ...rest } = props;
        return (
            <Link className={allClassNames} {...rest}>
                <DefaultInner {...props} />
            </Link>
        );
    } else if (props.as === 'icon-only') {
        const { Icon } = props;
        return (
            <button className='button-icon'>
                <Icon className='button-icon__svg' />
            </button>
        );
    } else if (props.as === 'text-button') {
        const { text, ...rest } = props;
        return (
            <button className='text-button' {...rest}>
                <span>{text}</span>
            </button>
        );
    } else {
        const { className, styleType, as, subText, Icon, ...rest } = props;
        return (
            <button className={allClassNames} {...rest}>
                <DefaultInner {...props} />
            </button>
        );
    }
}

export { Button };
