import './Experience.css';
import jobs from './Experience.json';
import { ReactComponent as ExternalLinkSvg } from '../../media/svg/external_link.svg';

type Props = React.HTMLProps<HTMLDivElement> & {};

function Experience({ ...rest }: Props) {
    return (
        <section {...rest}>
            <h3 className='section-header'>Experience</h3>

            {jobs.map((job, index) => {
                return (
                    <a
                        className='job'
                        key={`job-${index}`}
                        href={job.link}
                        rel='noreferrer noopener'
                        target='_blank'
                    >
                        <span className='job__background' />
                        <span className='job__length mb-1'>{job.length}</span>
                        <div className='mb-1 flex space-between'>
                            <h4 className='job__title'>{job.title}</h4>
                            <ExternalLinkSvg className='external-link' />
                        </div>
                        <div className='job__description mb-1'>
                            <p>{job.description}</p>
                        </div>
                        <div>
                            {job.skills.map((skill, i) => (
                                <span key={`job-${index}-skill-${i}`} className='job__technologies__skill'>
                                    {skill}
                                </span>
                            ))}
                        </div>
                    </a>
                );
            })}

            {/* <h3 className='resume-link'>
                <a href='/' className='mr-1'>
                    View Full Resume
                </a>
                <ExternalLinkSvg className='external-link' />
            </h3> */}
        </section>
    );
}

export default Experience;
