import { ReactComponent as Console } from '../../../../media/svg/console.svg';
import { ReactComponent as Log } from '../../../../media/svg/log.svg';
import { ReactComponent as Submit } from '../../../../media/svg/submit.svg';
import { ReactComponent as Info } from '../../../../media/svg/info.svg';
import { useContext, useEffect, useState } from 'react';
import './Question1.css';
import { GuessCountContext } from '../../../../context/GuessCount/guess-count';
import { Tooltip } from '../../../../Components/Tooltip/Tooltip';
import { Button } from '../../../../Components/Button/Button';
import GuardedQuestionRoute from '../../../../context/QuizRouteGuard/QuizRouteGuardContext';

function Question1() {
    const [enterredAnswer, setEnteredAnswer] = useState('');
    const [showHint, setShowHint] = useState(false);

    const { handleSetGuessesLeft, handleCorrectAnswer } = useContext(GuessCountContext);

    useEffect(() => {
        console.log('Answer: HelloWorld');

        setTimeout(() => {
            setShowHint(true);
        }, 10000);
    }, []);

    // TODO: Split functionality. Find way to merge this http request with the one in Blog.tsx
    // TODO: Create shared success / failure overlay component.
    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const response = await fetch('http://localhost:4000/quiz/1', {
            body: JSON.stringify({ answer: enterredAnswer }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const json = await response.json();
            const { correct } = json;

            correct ? handleCorrectAnswer() : handleSetGuessesLeft(-1);
        }
    }

    function handleAnswerChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEnteredAnswer(event.target.value);
    }

    return (
        <div className='question-wrapper'>
            <div className='question-inner'>
                {/* TODO: Figure out styling so that text uses space up until max width but stays small when little content */}
                {showHint && (
                    <div className='hint'>
                        <Tooltip direction='left' content='Console  Log' delay={10}>
                            <Info />
                        </Tooltip>
                    </div>
                )}
                <div className='icon-container flex j-center mb-5'>
                    {<Console />}
                    {<Log />}
                </div>
                <form onSubmit={handleSubmit} className='flex j-center'>
                    <div className='input-group flex'>
                        <input
                            className='control-input'
                            id='answer'
                            name='answer'
                            type='text'
                            value={enterredAnswer}
                            onChange={handleAnswerChange}
                            placeholder='Answer...'
                        />
                        <Button as='icon-only' styleType='success' Icon={Submit} />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default GuardedQuestionRoute(Question1);
