function Footer() {
    return (
        <footer>
            <p>
                Designed in Figma and coded in Visual Studio Code by me. Built using ReactTS and plain old
                CSS, deployed with Netlify. Fonts used are JosefinSans and Inter.
            </p>
        </footer>
    );
}

export default Footer;
