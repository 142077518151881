import { ReactNode, useState } from 'react';
import './Tooltip.css';

type Props = {
    children: ReactNode;
    direction: string;
    content: string;
    delay: number;
};

// Guide: https://codesandbox.io/s/how-to-make-an-extremely-reusable-tooltip-component-with-react-and-nothing-else-forked-fnllqs?file=/src/App.js

const Tooltip = ({ children, direction, content, delay }: Props) => {
    const [active, setActive] = useState(false);
    let timeout: ReturnType<typeof setTimeout>;

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <div className='Tooltip-Wrapper' onMouseEnter={showTip} onMouseLeave={hideTip}>
            {children}

            {active && <div className={`Tooltip Tooltip-Tip ${direction || 'top'}`}>{content}</div>}
        </div>
    );
};

export { Tooltip };
