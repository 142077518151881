import './Home.css';
import About from '../../Components/About/About';
import Experience from '../../Components/Experience/Experience';
import Header from '../../Components/Header/Header';

import Footer from '../../Components/Footer/Footer';
import { useEffect, useRef, useState } from 'react';

export const about = 'about';
export const experience = 'experience';
export const blog = 'blog';

function Home() {
    const mainRef = useRef<HTMLDivElement>(null);
    const backgroundRef = useRef<HTMLDivElement>(null);
    const homepageRef = useRef<HTMLDivElement>(null);

    const [sectionInView, setSectionInView] = useState('');

    useEffect(() => {
        if (mainRef.current) {
            const observerCallback: IntersectionObserverCallback = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setSectionInView(entry.target.id);
                    }
                });
            };

            const observer = new IntersectionObserver(observerCallback, { threshold: 0.7 });

            const children = Array.from(mainRef.current.children);

            const sections = children.filter((child) => child.tagName === 'SECTION');

            sections.forEach((ref) => {
                observer.observe(ref);
            });
        }

        if (backgroundRef.current && homepageRef.current) {
            const rect = backgroundRef.current.getBoundingClientRect();
            homepageRef.current.addEventListener('mousemove', (e: MouseEvent) => {
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                backgroundRef.current!.style.background = `radial-gradient(600px at ${x}px ${y}px, rgba(var(--non-photon-blue-rgb), 0.05), transparent 80%)`;
            });
        }
    });

    return (
        <div className='homepage-container' ref={homepageRef}>
            <div ref={backgroundRef} className='mouse-gradient' />

            <Header className='col' sectionInView={sectionInView} />
            <main ref={mainRef} className='col'>
                <About id={about} className='mb-8' />
                <Experience id={experience} className='mb-8' />
                {/* <Blog id={blog} className='mb-8' /> */}
                <Footer />
            </main>
        </div>
    );
}

export default Home;
