import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './Question2.css';

import { ReactComponent as Circle } from '../../../../media/svg/question-2/circle.svg';
import { ReactComponent as Heptagon } from '../../../../media/svg/question-2/heptagon.svg';
import { ReactComponent as Hexagon } from '../../../../media/svg/question-2/hexagon.svg';
import { ReactComponent as Pentagon } from '../../../../media/svg/question-2/pentagon.svg';
import { ReactComponent as Square } from '../../../../media/svg/question-2/square.svg';
import { ReactComponent as Triangle } from '../../../../media/svg/question-2/triangle.svg';
import { GuessCountContext } from '../../../../context/GuessCount/guess-count';
import GuardedQuestionRoute from '../../../../context/QuizRouteGuard/QuizRouteGuardContext';

const arr = [
    { key: 'Circle', Component: Circle, correct: false },
    { key: 'Circle', Component: Circle, correct: false },
    { key: 'Triangle', Component: Triangle, correct: false },
    { key: 'Triangle', Component: Triangle, correct: false },
    { key: 'Square', Component: Square, correct: false },
    { key: 'Square', Component: Square, correct: false },
    { key: 'Pentagon', Component: Pentagon, correct: false },
    { key: 'Pentagon', Component: Pentagon, correct: false },
    { key: 'Hexagon', Component: Hexagon, correct: false },
    { key: 'Hexagon', Component: Hexagon, correct: false },
    { key: 'Heptagon', Component: Heptagon, correct: false },
    { key: 'Heptagon', Component: Heptagon, correct: false },
].sort(() => (Math.random() > 0.5 ? 1 : -1));

function Question2() {
    const { handleCorrectAnswer, handleSetGuessesLeft } = useContext(GuessCountContext);

    const [[first, second], setFlip] = useState<(number | undefined)[]>([undefined, undefined]);
    const [values, setValues] = useState(arr);

    useEffect(() => {
        if (values.every((i) => i.correct)) {
            handleCorrectAnswer();
        }
    }, [handleCorrectAnswer, values]);

    const onFlip = useCallback(
        (index: number) => {
            // Prevent clicking a third option before timeout finishes.
            // Use typeof because index 0 is falsey.
            if (typeof first === 'number' && typeof second === 'number') return;

            // Prevent clicking flipped flipcard.
            if (first === index) return;

            if (typeof first === 'number') {
                setFlip([first, index]);

                const a = values[first];
                const b = values[index];

                if (a.key === b.key) {
                    const newValues = [...values];
                    newValues[first].correct = true;
                    newValues[index].correct = true;

                    setValues(newValues);
                } else {
                    handleSetGuessesLeft(-1);
                }

                setTimeout(() => {
                    setFlip([undefined, undefined]);
                }, 1500);
            } else {
                setFlip([index, second]);
            }
        },
        [first, handleSetGuessesLeft, second, values],
    );

    const isFlipped = useCallback(
        (index: number, correct: boolean) => {
            if (first === index || second === index || correct) {
                return ' flip';
            } else {
                return '';
            }
        },
        [first, second],
    );

    const cards = useMemo(() => {
        return values.map((shape, i) => {
            return (
                <div
                    className={'flipcard' + isFlipped(i, shape.correct)}
                    onClick={() => onFlip(i)}
                    key={'flipcard-' + i}
                >
                    <div className='flipcard__side flipcard__side--front' />
                    <div className={`flipcard__side flipcard__side--back ${shape.key}`}>
                        <div className='flex j-center a-center h-100'>
                            <shape.Component className='flipcard__shape h-75 w-75' />
                        </div>
                    </div>
                </div>
            );
        });
    }, [isFlipped, onFlip, values]);

    return (
        <div className='question-wrapper'>
            <div className='question-inner'>
                <div className='Question2-grid'>{cards}</div>
            </div>
        </div>
    );
}

export default GuardedQuestionRoute(Question2);
