import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
    const [countdown, setCountdown] = useState(3);
    const redirect = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((countdown) => countdown - 1);
        }, 1000);

        if (countdown === 0) {
            clearInterval(timer);
            redirect('/');
        }

        return () => clearInterval(timer);
    }, [countdown, redirect]);

    return (
        <div className='w-100 flex-col j-center a-center'>
            <h1>404 Page Not Found</h1>
            <h3>Redirecting in {countdown} seconds...</h3>
        </div>
    );
}

export default PageNotFound;
