import { Navigate } from 'react-router-dom';
import { useCurrentQuestionId } from '../../routes/Quiz/shared/useCurrentQuestionId';

// TODO: Better typing
const GuardedQuestionRoute = (Component: any) => {
    const WrappedComponent = (props: any) => {
        const currentQuestionId = useCurrentQuestionId();
        const lsCurrentQuestion = localStorage.getItem('currentQuestion');

        if (currentQuestionId === Number(lsCurrentQuestion)) {
            return <Component {...props} />;
        }

        return <Navigate to={`/quiz/questions/${Number(lsCurrentQuestion)}`} />;
    };

    return WrappedComponent;
};

export default GuardedQuestionRoute;
